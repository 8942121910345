body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tableContainer {
  height: 100vh;
  background: url("../src/assets/main-bg\ 1.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer .card{
  width: 100%;
  padding: 45px 40px;
  box-shadow: 0 2px 4px 0 rgba(14,30,37,.12), 0 2px 16px 0 rgba(14,30,37,.32);
}

.salesStyle a, .salesStyle span{
 color:#000 !important;
 font-weight:600 !important;
}
.salesTable .ant-pagination{
  display: none;
}