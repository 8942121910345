html,
body {
  font-family: "Poppins", sans-serif !important;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #898989;
}
/*-----------------------iframe-order-page-------------------------*/
.menu-css {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.example {
  top: 300px;
  left: 200px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

/*--------------------------navbar start----------------------------*/
.navbar {
  overflow: hidden;
  background-color: #07182c;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999 !important;
  padding: 10px 1rem !important;
  left: 0;
  color: white;
  border-bottom: 2px solid #ffffff;
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 10px 0px;
}
.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}
.navbar button {
  background: transparent;
  border: none;
}
/*---------------------------navbar end-----------------------------*/

/*--------------------------common start----------------------------*/
a {
  color: #898989 !important;
}
.App {
  border-color: transparent;
}
h4,
h3,
p,
a,
.logo {
  letter-spacing: 1px;
}
.row {
  margin: 0;
}
h6 {
  font-weight: bold !important;
}
/*--------------------------common end----------------------------*/

/*---------------------------cart-start---------------------------*/
.cart-quantity {
  line-height: 2;
}
.CartSection {
  float: left;
  width: 25%;
  margin: 35px 30px;
  text-align: right;
}
.CartSection h3 {
  margin-bottom: 0 !important;
  margin-top: 10px;
  font-weight: 600;
}
.CartSection h4 {
  margin-top: 0px;
  font-size: 14px;
  margin-bottom: 18px;
  font-weight: 600;
}
.CartItems {
  height: 70vh !important;
}
.cartWrapper {
  color: #ffffff;
  font-size: 30px;
}
/*----------------------------cart-end----------------------------*/

/*---------------------------card-start---------------------------*/
.card {
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.4);
  border: none !important;
  margin-bottom: 30px;
}
.checkout-card {
  margin-bottom: 30px;
}
.checkout-card .card-header,
.checkout-card .card-footer {
  background: #fff;
}
.otp-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.CardItem {
  flex: 0 0 auto !important;
  margin-bottom: -3px !important;
}
.card > .list-group:last-child {
  height: 580px;
  overflow-y: scroll;
}
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}
/*-----------------------------card-end-----------------------------*/
.cardHover {
  overflow-y: hidden !important;
}
/*-----------------------btn customization start-------------------*/
.btn-secondary {
  color: black !important;
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;
}
.add_product button {
  position: absolute;
  top: 0px;
}
.add_product .add_btn {
  right: 0px;
}
.view_order_btn {
  position: "fixed";
  z-index: 999;
  box-shadow: 10px;
  align-self: "flex-end";
  font-size: 16px;
  right: 307px;
}
.btn-success {
  background-color: #07182c !important;
}
button.vippsCard.btn.btn-warning{
  background-color: #ff5b24;
  padding: 20px;
}
.vippsCard {
  background-image: url(./assets/vippsbtn.png);
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center;
}
.vippssCard {
  background-image: url(./assets/vippssbtn.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 48px;
}
.btn-close {
  background-image: url(./assets/closeSign.svg);
  background-repeat: no-repeat;
  box-sizing: content-box;
  width: 0.25em;
  height: 0.25em;
  padding: 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.link-button small {
  color: #07182c;
  border-radius: 0.25rem;
}
.btn .btn-danger .btn-sm {
  padding: 0.1rem 0.8rem !important;
}
.btn-danger {
  background-color: #07182c !important;
  border-color: #07182c !important;
  color: white !important;
}
.btn-danger:focus {
  box-shadow: none !important;
}
.btn-success {
  border-color: transparent !important;
}
.col-1.btnSize {
  max-width: 100% !important;
}
.tableOrderButton {
  border-radius: 0.3rem !important;
}
.ButtonContainer {
  position: fixed;
  bottom: 0;
  left: 22%;
  padding: 10px;
  right: 0;
}
/*-----------------------btn customization end-----------------------*/

/* ------------------------Check mark start---------------------------*/
.checkmark-circle {
  width: 150px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 100px;
  background-color: #28a745 !important;
}
.checkmark-circle .background {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: "";
  left: 35px;
  top: 75px;
  position: absolute;
}
/* ------------------------Check mark end---------------------------*/

/*-------------------------- Main Container start----------------------------*/
#reserveTable{
  color: #000 !important;
}
.reserveHead h4{
  font-weight:600 !important;
}
#reserveTable label{
  font-weight: 600;
  display: flex;
  align-items: start;
  /* width: 100px; */
}
.main-container {
  height: 100vh;
  background: url("./assets/main-bg-opacity.jpg");
  background-size: cover;
}
.main-container .row {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  background: rgba(255,255,255,0.9);
  padding: 30px 50px;
  width: 70vw;
  /* height: 80vh; */
  border-radius: 10px;
}
.main-container .form-control {
  height: calc(1.2rem + 0.8rem + 1px);
  padding: 0px;
  border-radius: 0;
}
.main-container .form-inline {
  justify-content: space-between;
}
.close {
  font-size: 30px;
}

#selectField {
  width: 65px;
}
.add-btn button {
  font-size: 25px;
  border-radius: 50px;
  color: #000 !important;
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #ced4da;
}
.reserveInput {
  border: 2px solid #721818 !important;
}
.main-container .form-inline .form-control {
  width: 200px;
}
.main-container .form-group {
  width: 45%;
  flex-direction: column;
}
.main-row {
  align-items: flex-start !important;
}
.errorMessage {
  text-align: left;
}
.sendBooking.btn {
  width: 275px !important;
  background-color: #12C751 !important;
  color: #fff !important;
  padding: 10px 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}
.sendBooking .modal-content {
  border-radius: 20px !important;
}
.sendBooking .modal-body h4 {
  text-align: center;
}
.error-page,
.loading-gif,
.success-payment {
  background: #12C751;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 1.7rem;
}
.error-page h3,
.loading-gif h3,
.success-payment h3,
.success-payment h6,
.success-payment p {
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0px;
}
.reserveHead{
  width: 100%;
}
.reserveHead p{
  font-size: 12px;
}
.reserveHead h5{
  font-size: 18px;
}
#reserveTable h2{
  font-weight: 800;
  font-size: 35px;
  color: #000;
}
/*-------------------------- Main Container end----------------------------*/

/* ------------------------data section start-------------------------*/
.data {
  width: 400px;
  margin: 0 auto;
  height: 575px;
}
ul.CategoryListing li {
  display: inline-block;
  padding: 15px 35px;
}
ul.CategoryListing a {
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
ul.CategoryListing {
  height: 530px;
  padding: 0;
  transition: all 0.9s;
}
ul.CategoryListing:hover {
  transition: all 0.9s;
}
ul.CategoryListing li:active {
  border-color: #d6a666;
  background-color: #d6a666 !important;
}
ul.CategoryListing li:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
ul.CategoryListing li:hover a {
  color: #fff;
}
.productPrice {
  display: none;
}
/* -------------------------data section end----------------------------*/

/* ----------------------subcategory section start-----------------------*/
.product-list-element {
  padding-top: 10px;
  background-color: #dedede !important;
  width: 40%;
}
.product-list-element ul li {
  position: relative;
  display: flex;
  align-items: center;
}
.product-list-element ul li button {
  margin-left: auto;
  width: 90px;
  padding: 5px;
}
.product-list-element ul li p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}
.product-list-element ul li span {
  text-align: left;
  font-weight: 600;
  color: #555;
}
.product-list-element .ContentCenter.Subcategory {
  margin-top: 10px;
  justify-content: flex-start !important;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  display: flex;
  justify-content: start;
  height: 530px !important;
}
/* -----------------------subcategory section end------------------------*/
/*-------------Pickup page start-----------*/
.textField {
  border: 1px solid #162a3e !important;
  border-radius: 0.75rem !important;
}

.btn-outline-success {
  border-color: transparent !important;
}
.btn-outline-success:hover {
  background-color: transparent !important;
}
/*-------------Pickup page end-----------*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*--------------------------scroll styling start--------------------------*/
ul {
  padding-left: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all 0.9s;
}
ul:hover {
  overflow-y: scroll;
  transition: all 0.9s;
}

ul::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

ul::-webkit-scrollbar:vertical {
  width: 7px;
}

ul::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #d6a666;
  transition: all 0.9s;
}
/*--------------------------scroll styling stop--------------------------*/

/*------------------------------sidebar start-----------------------------*/
.sidebar_element {
  width: 98%;
}
.sidebar_element .list-group {
  padding: 20px 10px;
}
.sidebar_element .list-group .list-group-item {
  border-top: none;
  border-right: none;
  border-left: none;
  margin-bottom: 1px;
  border-radius: 0.75rem !important;
}
.sidebar_element {
  align-items: center;
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  flex-direction: column;
}
.sidebar_element ul {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
}
.sidebar_element ul li {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
/*------------------------------sidebar start-----------------------------*/

/*----------------------------add product start---------------------------*/
.add_product {
  position: relative;
}
.add_product input {
  text-align: center;
  padding: 0px 30px;
}
/*-----------------------------add product end----------------------------*/

/*------------------------------alert - start-----------------------------*/
.alert-div {
  position: fixed;
  background: #636e72;
  color: white;
  padding: 10px;
  top: 10px;
  margin-bottom: 10px;
  opacity: 0.8;
  width: 98%;
  z-index: 999;
  top: 0px;
  font-weight: bolder;
  cursor: pointer;
}
.alert-div :hover {
  position: fixed;
  background: #333;
  padding: 10px;
  color: white;
  font-weight: bolder;
  opacity: 1;
  cursor: pointer;
  top: 0px;
  width: 65.8%;
  z-index: 999;
}
/*------------------------------alert - start-----------------------------*/

/*---------------------------modal section start--------------------------*/
.modal {
  overflow-y: scroll !important;
}
.modal.show .modal-dialog {
  margin-top: 75px;
  -webkit-transform: none;
  transform: none;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  flex-wrap: wrap;
}
.modalheader {
  justify-content: end !important;
  border-bottom: 1px solid transparent !important;
}
/*---------------------------modal section end--------------------------*/
.InnerContent {
  padding-top: 40px;
}
.ContentCenter {
  padding: 22px 30px 20px 0;
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start !important;
}
.ContentCenter::after {
  border-bottom: 1px solid #0c0b0b;
}
.ImgSec {
  float: left;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  height: 530px !important;
}
.ImgSec {
  overflow-y: scroll;
  transition: all 0.9s;
}

.ImgSec::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

.ImgSec::-webkit-scrollbar:vertical {
  width: 7px;
}

.ImgSec::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #d6a666;
  transition: all 0.9s;
}
.ImgSec .box {
  margin: 0 auto;
  width: 100%;
}
.ImgSec img {
  width: auto;
  display: inline;
  height: 350px;
}
.OrderSection {
  text-align: left;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.orderButton {
  text-align: right;
  margin-top: 10px;
}
.OrderSection h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 500;
}
.OrderSection p {
  /* color: #898989 !important; */
  font-size: 15px;
  font-weight: 500;
  color: #000 !important;
}
.OrderSection h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 7px;
}
.OrderSection a {
  color: rgb(27, 47, 68) !important;
  padding: 8px 17px;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
ul.SubCategoryList img {
  border-radius: 50px;
  height: 60px;
  width: 60px;
}
ul.SubCategoryList li {
  display: flex;
  border-radius: 8px;
  margin-top: 10px;
  padding: 5px;
}
ul.SubCategoryList li:hover {
  background: rgba(0, 0, 0, 0.1);
}
ul.SubCategoryList h3 {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 20px !important;
}
ul.SubCategoryList a {
  text-decoration: none;
  color: #000;
  font-size: 13px;
  line-height: 20px;
}
.form-check {
  padding-left: 0 !important;
}
.wrapper {
  display: flex;
}
.mainWrapper {
  overflow-y: hidden;
  margin-top: -14px;
  height: 100vh;
}
.CategoryListing {
  display: flex;
  flex-direction: column;
}
.Subcategory h6 {
  margin-top: 20px;
  padding-left: 20px;
}
.badge {
  position: absolute !important;
  background: #d6a666 !important;
  color: #000000 !important;
  border-radius: 50px !important;
  padding-top: 4px !important;
  right: 10px;
}
.list-group-item {
  padding: 0.75rem 1.75rem !important;
  margin-top: 10px;
}
.product-list-element ul.list-group {
  overflow-y: scroll;
  padding: 0;
}
.product-list-element h5 {
  margin-bottom: 0px;
  padding: 10px 15px;
  text-align: left;
  background: #333;
  margin-top: 20px;
  color: #fff;
  margin-left: 27px;
  font-weight: 600;
}
.totaltcl {
  width: 83%;
  margin: 0 auto;
}
.totaltcld {
  width: 74%;
  margin: 0 auto;
}
.Menustyle {
  text-align: left;
  font-weight: bold;
}
.tab-main-element {
  margin-top: 60px;
}
.logo {
  font-size: 20px !important;
  padding-left: 0 !important;
}
/* sidebr element css */
.plate-image {
  background-image: url("./assets/plate.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.subtotal-list {
  align-items: flex-end;
  justify-content: space-around;
  display: flex;
  bottom: 1px;
  width: 100%;
}
.total_product_element {
  bottom: 0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}
.total_product_element ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.total_product_element {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 6px !important;
  height: 50px !important;
}
.total_product_element ul li span.price,
.total_product_element ul li span.subtotal {
  font-weight: 300;
  font-size: 16px;
}
.total_product_element ul li textarea {
  width: 100%;
  padding: 10px;
  height: 50px;
}
.total_product_element ul li button {
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}
.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  margin-top: 15px;
}
.checkboxList {
  padding: 0 20px;
}
.delivery-options-buttons {
  display: flex;
  justify-content: space-evenly;
}
.res-logo {
  max-width: 80px;
  max-height: 80px;
}
/* Product card text start */
.productCardText {
  overflow-y: scroll;
  transition: all 0.9s;
}

.productCardText::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

.productCardText::-webkit-scrollbar:vertical {
  width: 7px;
}

.productCardText::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.9s;
}
.btn-warning {
  border-color: transparent !important;
}
.submenu-css {
  overflow-y: hidden;
}
/* Product card text End */
/*----------------------media query section-------------------------------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .ContentCenter.img-cc {
    display: none;
  }
  .ContentCenter.Subcategory ul {
    overflow-y: scroll;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .break {
    display: none;
  }
  .menu-css {
    overflow-y: scroll;
  }
  ul.CategoryListing li {
    padding: 10px 20px;
  }
  .wrapper {
    display: block !important;
    /* margin-top: 40px; */
    position: relative !important;
  }
  .data {
    height: auto;
    width: auto !important;
    border: 1px solid #dedede;
    background: #dedede;
    box-shadow: rgb(222 222 222 / 54%) 23px 44px 44px 0px;
    padding-bottom: 10px;
  }
  .product-list-element {
    width: auto !important;
  }
  .CategoryListing {
    height: auto !important;
    text-align: center !important;
  }
  .ImgSec img {
    height: auto !important;
  }
  .badge {
    padding: 3px 7px !important;
  }
  .modal-footer .btn {
    font-size: 11px;
  }
  .ContentCenter.img-cc {
    display: none;
  }
  .product-list-element .ContentCenter.Subcategory {
    width: 100%;
  }
  .product-list-element .ContentCenter.Subcategory {
    height: auto !important;
  }
  .form-control {
    height: calc(1.5em + 0.75rem + -5px) !important;
  }
  .submenu-css {
    overflow-y: scroll;
  }
  .product-list-element .ContentCenter.Subcategory {
    border-radius: 0px !important;
  }
  ul.SubCategoryList li {
    padding-right: 30px;
  }
  .productPrice {
    display: block;
  }
  .productPriceWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /*--------------------------maincontainer----------------------*/
  .main-container {
    height: 100%;
  }
  .main-container .row {
    padding: 10px;
    /* background: url(./assets/mobile.bg.png); */
    width: 100%;
  }
  .main-container .main-row {
    padding: 0 !important;
    flex-direction: column;
  }
  .main-container .form-inline {
    padding: 0 !important;
  }
  .main-container h2 {
    font-size: 1.2rem;
  }
  .main-container .form-group {
    width: 100%;
    display: block !important;
  }
  .main-container .form-inline .form-control {
    width: 240px;
  }
  #Note {
    width: 100%;
  }
  .errorMessage {
    margin-left: 0px;
  }
  .img-box {
    padding-bottom: 20px;
  }
  .main-container .form-inline label {
    font-size: 12px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 567px) {
  .mainWrapper {
    height: auto !important;
  }
  .logo {
    font-size: 15px !important;
  }
  .product-list-element {
    margin-bottom: 0px !important;
  }
  .ButtonContainer {
    left: 0% !important;
  }
  .btn-warning {
    margin-top: 0px !important;
  }
  .bgCard {
    background-image: url(./assets/bg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: scroll;
  }
}
@media (max-width: 568px) {
  .outerCard {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) {
  .ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 0%;
    padding: 0px;
    right: 0;
  }
  .tableOrderButton {
    margin-bottom: 15px;
  }
  .main-container .form-inline .form-control {
    width: 300px;
  }
  .errorMessage {
    margin-left: 0px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 896px) and (orientation: landscape) {
  .wrapper {
    position: relative !important;
    display: block;
  }
  ul.CategoryListing {
    display: block;
  }
  .data {
    width: 100%;
    padding: 25px 0px 0px;
  }
  .CategoryListing {
    margin-bottom: 0;
  }
  .product-list-element {
    width: 100%;
  }
  .btn-warning {
    margin-top: 0px !important;
  }
  .main-container {
    height: 100%;
  }
  .main-container .main-row {
    display: flex !important;
  }
  .main-container .form-inline .form-control {
    width: 125px;
  }
  .main-container .form-inline label {
    font-size: 12px;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 640px) {
  .CartSection {
    width: 100%;
    margin: 0px 2px 25px;
    text-align: center;
  }
  .ImgSec {
    width: 90%;
    float: none;
    display: block;
    margin: 0px auto;
    text-align: center;
  }
  .data {
    padding-top: 40px;
  }
  .ImgSec .box {
    width: auto !important;
  }
  .OrderSection {
    margin: 0px;
    text-align: center;
    width: 100%;
    display: block !important;
  }
  .ImgSec {
    height: auto !important;
  }
  .InnerContent {
    padding-bottom: 40px;
    background-size: cover;
  }
  .CategoryListing {
    display: block;
  }
  .CategoryListing {
    display: block !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 896px) and (orientation: landscape) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  body .product-list-element {
    padding-top: 1px;
  }
  .wrapper .data,
  .wrapper ul.CategoryListing {
    height: auto !important;
  }
  .data,
  .product-list-element {
    width: 100% !important;
  }
  ul.CategoryListing {
    width: 100%;
    display: block;
  }
  .mainWrapper {
    overflow-y: scroll;
  }
}
@media only screen and (min-device-width: 1440px) and (max-device-width: 3440px) and (orientation: landscape) {
  ul.CategoryListing {
    height: 90vh;
    padding: 0;
    overflow-x: scroll;
  }
}
@media screen and (min-device-width: 690px) and (max-device-width: 768px) {
  .ImgSec {
    width: 24% !important;
    float: left;
    text-align: center;
  }
  .CartSection {
    width: 28% !important;
    margin: 8px 25px;
  }
  .OrderSection {
    margin: 10px 30px;
    width: 20% !important;
  }
  .ContentCenter {
    width: 85% !important;
  }
}
@media (max-width: 736px) {
  .CardItem {
    padding: 10px !important;
  }
  .lineContainer {
    margin-top: 0.25rem !important;
    margin-bottom: 0.15rem !important;
  }
}
@media (max-width: 812px) {
  .CardItem {
    padding: 2px !important;
  }
  .lineContainer {
    margin-top: 0.25rem !important;
    margin-bottom: 0.15rem !important;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media screen and (min-device-width: 641px) and (max-device-width: 1920px) {
  .data {
    margin-top: 0 !important;
  }
  .InnerContent {
    padding-bottom: 40px;
    background-size: cover;
  }
  .CartSection {
    width: 27%;
    margin: 8px 30px;
  }
  .mainWrapper .row {
    height: 100vh !important;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1300px) {
  .ImgSec img {
    width: 400px;
    height: 300px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 1366px) {
  .main-container {
    padding:30px !important;
    height: auto;
  }
  #reserveTable h2 {
    font-size: 18px;
}
.reserveHead p,span,div {
  font-size: 12px;
}
  .ImgSec img {
    width: 360px;
    height: 250px;
  }
  .reserveHead img {
    height: 80px;
}
  .main-container .row {
    padding: 20px;
  }
  h4{
    font-size:14px !important;
  }
  .main-container .form-control {
    height: calc(0.9rem + 0.8rem + 1px);
}
.add-btn button {
  font-size: 20px;
  width: 30px;
  height: 30px;
}
}
@media only screen and (min-device-width: 550px) and (max-device-width: 1100px) {
  .ImgSec img {
    height: 250px;
  }

  ul.CategoryListing {
    height: 460px !important;
  }
  .ContentCenter {
    padding: 30px 30px 0px 0;
    justify-content: start !important;
  }
  body .product-list-element {
    align-items: flex-start;
  }
  .product-list-element .ContentCenter.Subcategory {
    margin-top: 20px;
    height: 400px !important;
  }
  .ContentCenter .ImgSec {
    height: 400px !important;
    padding: 10px;
  }
  ul.SubCategoryList img {
    height: 50px;
    width: 50px;
  }
  .badge {
    padding: 2px 7px !important;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1300px) {
  ul.CategoryListing {
    overflow-y: scroll !important;
  }
  ul.SubCategoryList {
    height: 400px !important;
    overflow-y: scroll !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
  ul.CategoryListing {
    height: 640px;
    overflow-y: scroll !important;
  }
  ul.SubCategoryList {
    overflow-y: scroll !important;
  }
}
@media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) {
  .wrapper {
    overflow: scroll !important;
  }
  ul.SubCategoryList {
    overflow-y: scroll;
  }
}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1200px) {
  .product-list-element .ContentCenter.Subcategory {
    height: 470px !important;
  }
  ul.SubCategoryList {
    overflow-y: scroll;
  }
  .ImgSec,
  ul.CategoryListing {
    height: 510px !important;
  }

  .ImgSec img {
    height: 250px;
  }
}
/*------------------------------------------laptop2------------------------------------------*/
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) {
  ul.CategoryListing {
    overflow: scroll;
    height: 700px;
  }
}
/*------------------------------------------ipadmini------------------------------------------*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .data {
    height: auto !important;
    width: 100%;
  }
  body .product-list-element .ContentCenter.Subcategory {
    height: 650px !important;
    overflow: scroll;
  }
  .ContentCenter.img-cc {
    display: none;
  }
  ul.SubCategoryList {
    overflow: scroll;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  body .product-list-element {
    height: 100vh;
    width: 100%;
  }
  ul.CategoryListing {
    height: auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .ButtonContainer {
    position: fixed;
    bottom: 0;
    left: 20%;
    padding: 0px;
    right: 0;
  }
  .btn-lg {
    font-size: 1.15rem !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  body .wrapper .product-list-element .ContentCenter.Subcategory,
  body .ContentCenter .ImgSec,
  body ul.CategoryListing {
    height: 460px !important;
  }
  ul.SubCategoryList {
    overflow-y: scroll;
  }
  .product-list-element ul li button {
    font-size: 14px !important;
  }
}
/*------------------------------------------big screen------------------------------------------*/
@media only screen and (min-device-width: 1600px) and (max-device-width: 2560px) {
  .product-list-element .ContentCenter.Subcategory,
  .ImgSec,
  ul.CategoryListing {
    height: 940px !important;
    overflow-y: scroll !important;
  }
  ul.SubCategoryList {
    overflow-y: scroll !important;
  }
  .product-list-element .ContentCenter.Subcategory {
    margin-top: 30px;
  }
}
@media only screen and (min-device-width: 2560px) and (max-device-width: 2560px) {
  .product-list-element .ContentCenter.Subcategory {
    margin-top: 25px;
  }
  .ImgSec,
  ul.CategoryListing,
  .product-list-element .ContentCenter.Subcategory {
    height: 1450px !important;
  }
  ul.CategoryListing a,
  ul.SubCategoryList h3,
  .OrderSection p {
    font-size: 16px;
  }
  .OrderSection h3 {
    font-size: 20px;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) {
  .vippsCard {
    width: 110px !important;
  }
  .total_product_element ul li button {
    width: 54%;
  }
}
@media only screen and (min-device-width: 736px) and (max-device-width: 1024px) and (orientation: landscape) {
  .bgCard {
    height: 800px;
    overflow-y: scroll;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
  .bgCard {
    height: 800px;
    overflow-y: scroll;
  }
}
.alert-warning {
  background-color: #d6a666 !important;
  color: #07182c !important;
  height: 55px !important;
}
.alert {
  margin-bottom: 0rem;
  padding: 0.8rem 1.25rem;
}
.termsLink {
  color: #162a3e !important;
  font-weight: bold;
}

.total_product_element {
  background-color: #162a3e !important;
  border-radius: 8px 8px 0px 0px !important;
}
.cardBtn {
  border-radius: 15px !important;
  border: 1px solid white !important;
}
.vippsCard {
  border-radius: 15px !important;
  border: 1px solid white !important;
}
.btn-outline-success {
  color: #28a745 !important;
}
.lagreBtn {
  color: white !important;
  background-color: #ba8a4c !important;
  border-radius: 0.75rem !important;
}
.link-button {
  color: white !important;
}
.cardBtn {
  border-color: white !important;
}

/* BookTable */
.productContainer {
  display: flex;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.product_Name {
  color: #1b2f44;
  font-weight: bold;
  font-size: 16px;
  padding-left: 0px !important;
}
.product_Price {
  color: #5c5c5c;
  font-weight: bold;
  font-size: 16px;
  padding-left: 0px !important;
}
.minusBtn {
  border: 1px solid #1b2f44 !important;
  border-radius: 8px !important;
}
.plusBtn {
  border: 1px solid #1b2f44 !important;
  border-radius: 8px !important;
}
.instructions {
  height: 80px;
  border-radius: 8px;
  width: 100%;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}
.accordionItems {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.ant-modal-body {
  padding: 0px !important;
}
.ant-modal-close-x {
  line-height: 35px !important;
  color: #ffffff;
}
.product_Modal {
  background-color: #1b2f44;
  border-radius: 0px !important;
  padding: 20px !important;
}
.productTitle {
  color: #1b2f44 !important;
  font-weight: bold;
}
.removebutton {
  height: 20px;
  line-height: 0.1rem;
  background: transparent !important;
  border: none !important;
  color: #1b2f44;
}
.cardScroll {
  overflow-y: scroll !important;
}
.checkoutMain {
  margin: 20px auto;
  width: 38%;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 / 40%);
  border-radius: 6px;
}
.subTotalwrapper {
  max-width: 100% !important;
  margin: auto;
}
.outerCard {
  width: 400px !important;
}

.checkoutMain {
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.9s;
  height: 580px;
  padding-top: 40px;
}

.checkoutMain::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

.checkoutMain::-webkit-scrollbar:vertical {
  width: 7px;
}

.checkoutMain::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.9s;
}
.addItems {
  background-color: #deedff;
  border: 2px solid #1b2f44;
  border-radius: 10px;
  padding: 2px 6px;
}
.mainRowWrapper {
  padding: 20px;
  margin-top: -40px;
}
.ingredientTitle {
  line-height: 0.5em;
}
.extraWrapper {
  display: flex !important;
  height: 25px !important;
  margin-top: 8px !important;
}
.tagContainer {
  color: #d6a666 !important;
  border: 1px solid #d6a666 !important;
  display: flex !important;
  border-radius: 8px !important;
}
.productDesc {
  color: rgb(137, 137, 137);
  font-size: 15px;
  font-style: italic;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-container {
    background: none;
    padding: 0 !important;
}
  .ant-modal-body {
    padding: 0px !important;
  }
  .checkoutMain {
    width: 100%;
  }
  .add-btn{
    flex-direction: column;
  }
  #reserveTable h2 {
    font-size: 25px;
}
  .ingredientTitle {
    line-height: 1.2em !important;
  }
  .outerCard {
    width: 100% !important;
  }
  .subTotalwrapper {
    max-width: 80%;
  }
  .CardItem {
    padding: 8px !important;
  }
  .reserveHead p{
    font-size: 10px;
  }
  h4{
    font-size: 18px !important;
  }
  .ButtonPay {
    margin-top: 12px !important;
  }
  .add-btn div{
    font-size: 20px !important;
    justify-content: center !important;
  }
  .reserveHead img {
    height: 100px;
}
  .add-btn button {
    font-size: 20px;
    width: 35px;
    height: 35px;}
  /* #selectField { */
    /* width: 90px; */
    /* margin-left: 0px; */
  /* } */
  /* #PHONENUMBER {
    width: 240px;
    margin-top: 10px;
    margin-left: 0px;
  } */
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  #selectField {
    width: 80px;
    margin-left: 8px;
  }
  .checkoutMain {
    width: 100% !important;
    height: 920px !important;
  }
  .CardItem {
    padding: 15px !important;
  }
  /* #PHONENUMBER {
    width: 114px;
  } */
}